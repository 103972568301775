const Menu = [
  {
    heading: "menu",
    route: "/report",
    pages: [
      {
        heading: "Pending Statistics",
        route: "/report/pending-statistics",
        fontIcon: "fa-user",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Batch Report",
        route: "/report/batch-monitor",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Student Report",
        route: "/report/course-student",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      // {
      //   heading: "Gender Wise Student",
      //   route: "/enrollment/training-license",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      {
        heading: "Student Attendance List",
        route: "/report/student-attendance-list",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Student Age Group List",
        route: "/report/student-age-group-list",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      
      {
        heading: "Monitor Trainee Attendance",
        route: "/report/monitor-trainee-attendance",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Trainee Allowance Summary",
        route: "/report/trainee-allownce-summary",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Implementation Progress Report",
        route: "/report/implementation-progress-report",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      // {
      //   heading: "Ongoing Course Summary",
      //   route: "/enrollment/training-license",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      // {
      //   heading: "Completed Course summary",
      //   route: "/enrollment/training-license",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      // {
      //   heading: "Ethnic Minority",
      //   route: "/enrollment/training-license",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      // {
      //   heading: "Physically Disable",
      //   route: "/enrollment/training-license",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      // {
      //   heading: "Poor",
      //   route: "/enrollment/training-license",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      {
        heading: "StiPend List Poor",
        route: "/report/stipend-list-poor",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Gender Equity",
        route: "/report/gender-equity",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Course Summary",
        route: "/report/course-summary",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      // {
      //   heading: "Course Summary(Private)",
      //   route: "/enrollment/training-license",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      // {
      //   heading: "Course Summary(BB-SME & PKSF)",
      //   route: "/enrollment/training-license",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      // {
      //   heading: "Course Summary(Monthly BreakDown)",
      //   route: "/enrollment/training-license",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
    ],
  },
];

export default Menu;
